import React, { useEffect } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import auth from "./service/auth";
import { Routes, Route } from "react-router-dom";
import ConnectApp from "./component/connectApp";
import PaymentCredentitals from "./component/PaymentCredentitals";
import ReadyEvent from "./component/ReadyEvent.js";

function App() {

  useEffect(() => {
    document.title = 'linked2checkout';
    console.log(window.location.pathname, "window.location.pathname");
    let Connectiondata = auth.isConnection();
    const queryParams = new URLSearchParams(window.location.search);
    // if (Connectiondata) {
    //   Navigate("/dashboard");
    // } else {
    //   Navigate("/ghlAauthentication/:id");
    // }
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route >
          <Route path="/instruction" element={<ConnectApp />} />
          <Route path="/" element={<PaymentCredentitals />} />
          <Route path="/readyEvent" element={<ReadyEvent />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
