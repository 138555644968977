import React, { useEffect, useState } from "react";
import axios from "axios";

const PaymentIframe = () => {
  const [loading , setLoading] = useState(false)

  useEffect(() => {
    // const eventData = {
    //   type: 'custom_provider_ready',
    //   loaded: true
    // };
    // const customEvent = new CustomEvent('iframeLoaded', { detail: eventData });
    // window.dispatchEvent(customEvent);
    // console.log('Iframe is fully loaded and event dispatched with data:', eventData);


    window.parent.postMessage(
      JSON.stringify({
        type: 'custom_provider_ready',
        loaded: true,
      }),
      '*'
    )
    const handleMessages = (event) => {
      console.log("Received payment data:", event.data);
      console.log("Received payment data parse:", JSON.parse(event.data));
      
      const payload = JSON.parse(event.data);
      if (payload.invoiceId && payload.locationId) {
        setLoading(true)
        const invoiceId = payload.invoiceId;
        const locationId = payload.locationId;
        const url = `${process.env.REACT_APP_URL}/oAuth/getInvoiceData?invoiceId=${invoiceId}&locationId=${locationId}`;
        axios.get(url)
          .then((response) => {
            console.log('Data successfully get:', response.data);
              // Redirect using the link from the API response
              const redirectionLink = response.data.data.link;
              if (redirectionLink) {
                window.location.href = redirectionLink;
              } else {
                console.error('No redirection link provided in the response');
              }
          })
          .catch((error) => {
            console.error('Error getting data:', error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        console.error('Invalid data received:', event.data);
      }

    };

    window.addEventListener('message', handleMessages);

   
  }, []);

  return (
    <div>
      <h2>{loading?"Loading...":"Payment Url Connected:" }</h2>
    </div>
  );
};

export default PaymentIframe;
